<template>
  <div class="recruitInfo w">
    <topFilterBox @change="chooseCity">
      <div class="filter_box" slot="first">
        <div class="filter_title">
          采购类型:
        </div>
        <div class="filter_content">
          <div :class="['filter_c_item',cur_father.name == pItem.name ? 'filter_active_item' : '']"
            v-for="(pItem,pIndex) in $store.state.categoryConfig.purchase" :key="pIndex" @click="choosefather(pIndex)">
            <el-popover placement="bottom" width="400" trigger="click" @show="showChildren" @hide="hideChildren">
              <div class="filter_children_box">
                <div :class="['children_item', cur_sons_name.indexOf(son.name) != -1 ? 'active_children_item' : '']"
                  v-for="(son, index) in cur_father.child" :key="index" @click.stop="chooseJob(son)">
                  {{ son.name }}
                </div>
              </div>
              <button class="filter_c_item_name" slot="reference">
                {{ pItem.name }}
                <div class="choose_num" v-if="curChooseNum(pItem)">{{ curChooseNum(pItem) }}</div>
              </button>
            </el-popover>
          </div>
        </div>
      </div>
      <div class="filter_box" slot="three">
        <div class="filter_title">
          时间:
        </div>
        <div class="filter_content">
          <div :class="['filter_c_item',(filter.start_time==pItem.start_time&&filter.end_time==pItem.end_time)? 'filter_active_item' : '']"
            v-for="(pItem,pIndex) in time_type" :key="pIndex" @click="chooseType(pItem,'start_time')">
            <button class="filter_c_item_name">
              {{ pItem.name }}
            </button>
          </div>
        </div>
      </div>

    </topFilterBox>
    <div class="list_box">
      <div class="lists_total_num_box">
        共找到<span>{{ list_data.total }}</span>条信息
      </div>
      <purchaseList itemType="list" :lists="list_data.lists" :numberRow="2" v-if="list_data.lists.length"></purchaseList>
      <el-empty :image-size="200" v-else></el-empty>
    </div>
    <Pager @changePage="changePage" @getList="getData" :page_size="page.page_size" :total="list_data.total"></Pager>
  </div>
</template>

<script>
import topFilterBox from '../../components/topFilterBox.vue';
import purchaseList from '../../components/purchase/purchaseList.vue';
import Tabs from '../../components/Tabs.vue'
import Pager from '../../components/pager/Pager.vue'
import mixin from '../../mixin/index'
import { mapState } from 'vuex';
export default {
  mixins: [mixin],
  components: {
    purchaseList,
    topFilterBox,
    Pager,
    Tabs
  },
  data () {
    return {
      cur_father: {},//记录当前选择的省份
      cur_fatherIndex: -1,//记录当前选择的省份下标
      cur_sons: [],//当前选择的城市
      cur_fathers_name: [],//当前选择的省份 用于显示激活css
      cur_sons_name: [],//当前选择的城市 用于显示激活css
      filter: {
        keyword: '',
        city_codes: [],
        category_ids: [],
        type: ''
      },
      time_type: [
        { name: '今日', value: 0, start_time: '', end_time: '' },
        { name: '近7天', value: 7, start_time: '', end_time: '' },
        { name: '近30天', value: 30, start_time: '', end_time: '' },
        { name: '近3个月', value: 90, start_time: '', end_time: '' },
        { name: '近6个月', value: 180, start_time: '', end_time: '' },
        { name: '近9个月', value: 270, start_time: '', end_time: '' },
      ],
    }
  },
  created () {
    this.initDateArr();
    this.getData();
  },
  computed: {
    ...mapState({
      keyword: state => state.keyword,
    }),
  },
  methods: {
    chooseCity (val) {
      this.filter.city_codes = val;
      this.getData();
    },
    choosefather (index) {
      this.cur_fatherIndex = index;
    },
    showChildren () {
      setTimeout(() => {
        this.cur_father = this.$store.state.categoryConfig.purchase[this.cur_fatherIndex];
      }, 10)
    },
    initDateArr () {
      this.time_type.forEach(item => {
        item.start_time = this.getDateWithOffset(item.value) + ' 00:00:00';
        item.end_time = this.getDateWithOffset(0) + ' 23:59:59';
      })
      console.log(this.time_type, '当前时间');
    },
    hideChildren () {
      this.cur_father = {};
    },
    curChooseNum (father) {
      let findfather = this.cur_sons.find(item => item.father_name === father.name);
      if (findfather) {
        let num = 0;
        this.cur_sons.forEach(item => {
          if (item.father_name == father.name) {
            num++;
          }
        });
        return num;
      } else {
        return 0;
      }
    },
    chooseJob (son) {
      console.log(son, '当前城市');
      if (this.cur_sons_name.indexOf(son.name) == -1) {
        if (this.cur_sons_name.length >= 3) {
          this.$message.error('最多只能选择三个类型');
          return;
        }
        this.cur_sons_name.push(son.name);
        this.cur_sons.push({
          ...son,
          father_name: this.cur_father.name,
          father_code: this.cur_father.code
        });
      } else {
        this.cur_sons_name = this.cur_sons_name.filter(item => item != son.name);
        this.cur_sons = this.cur_sons.filter(item => item.name != son.name);
      }
      let sonCode = this.cur_sons.map(item => {
        return item.id;
      })
      this.filter.category_ids = sonCode;
      this.getData();
    },
    chooseType (val, type) {
      console.log(val, '当前点击');
      if (type == 'start_time') {
        if (this.filter.end_time == val.end_time) {
          this.filter.start_time = "";
          this.filter.end_time = "";
        } else {
          this.filter.start_time = val.start_time;
          this.filter.end_time = val.end_time;
        }
      }
      this.getData();
    },

    getData () {
      let params = {
        ...this.filter
      }
      params.city_codes += '';
      params.category_ids += '';
      if (this.keyword.length) {
        params.keyword = this.keyword;
      }
      for (let key in params) {
        if (!params[key] && params[key] !== 0) {
          delete params[key];
        }
      }
      this.getList('getPurchaseList', this.page, params);
    }
  },
  watch: {
    keyword (val) {
      this.filter.keyword = val;
      this.getData();
    }
  }
}
</script>

<style lang="scss">
.recruitInfo {
  min-height: 100vh;
  padding-bottom: 92px;
  .w {
    width: 1200px;
    margin: 0 auto;
  }
  .tabs_box {
    margin-bottom: 27px;
  }
  .list_box {
    .lists_total_num_box {
      font-size: 20px;
      font-weight: 400;
      color: #666666;
      margin: 24px 0 29px;
      span {
        color: #3f63ff;
      }
    }
  }
}
</style>